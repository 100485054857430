import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { TextInput, Spinner, Alert, Button, Tooltip, Badge } from "flowbite-react";
import Modal from "react-bootstrap/Modal";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import clone from "just-clone";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaRegEdit, FaRegCheckCircle } from "react-icons/fa";

import app from "../../firebase.config";
import { StdContext } from "../../context/StdContext";
import LoadingAnimation from "../LoadingAnimation";
import { GetUserNameFromBookingRefStr, GetUserIdFromBookingRefStr, GetServerTime } from "../../helpers/utils";
import PriceSummary from "./PriceSummary";
import Booking from "../../helpers/Booking";

const globals = require("../../helpers/globals.json");
const Constants = globals.Constants;
const Collections = globals.Collections;

const columns = [
    {
        field: "timestamp",
        headerName: "Modified Date",
        width: 150,
        editable: false,
        renderCell: ({ value }) => {
            return value.toDateString();
        }
    },
    {
        field: "name",
        headerName: "Modified By",
        width: 150,
        editable: false
    },
    {
        field: "phone_number",
        headerName: "Phone Number",
        width: 150,
        editable: false
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: value => {}
    },
    {
        field: "comments",
        headerName: "Comments",
        width: 250,
        editable: false,
        renderCell: value => {}
    }
];

const ViewBooking = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const booking_id = params.get("id");

    const { user_phone_number, IsAdmin, isFetching, user_data } = useContext(StdContext);
    const [admin_view, SetAdminView] = useState(false);
    // const user_data = GetUserData();
    const [booking_obj, SetBookingObj] = useState(null);
    const [fetching_booking_obj, SetFetchingBookingObj] = useState(true);
    const [admin_action_history, SetAdminActionHistory] = useState([]);
    // UI stuff
    const [booking_deletion_in_progress, SetBookingDeletionInProgress] = useState(false);
    const [booking_acceptance_in_progress, SetBookingAcceptanceInProgress] = useState(false);

    const [show_unauthorized_access, SetShowUnauthorizedAccess] = useState(false);
    const [show_delete_confirmation, SetShowDeleteConfirmation] = useState(false);
    const [show_cancel_booking_request, SetShowCancelBookingRequest] = useState(false);
    const [show_accept_booking_request, SetShowAcceptBookingRequest] = useState(false);

    const [rejectionNote, setRejectionNote] = useState(""); // Do NOT set the initial state to null, since it will be used inside a textarea
    const [error, setError] = useState(false);
    const [current_time, SetCurrentTime] = useState(null);
    const [request_approval_mode, SetRequestApprovalMode] = useState(false);

    const [payment_ref, SetPaymentRef] = useState("");
    useEffect(() => {
        if (booking_obj !== null) return;

        const GetBookingDetails = async () => {
            SetAdminView(user_data.isAdmin);
            // if (NoData()) return;

            const current_time = await GetServerTime();
            SetCurrentTime(current_time);

            const db = getFirestore(app);
            const booking_ref = doc(db, Collections.BOOKINGS, booking_id);
            const booking_doc = await getDoc(booking_ref.withConverter(Booking.FirestoreConverter));
            if (booking_doc.exists()) {
                console.warn("Querying data");
                const booking_data = booking_doc.data();
                const user_ref = doc(db, Collections.USERS, booking_data.GetUserId());
                const user_doc = await getDoc(user_ref);
                if (
                    user_doc.exists() &&
                    (user_data?.isAdmin ||
                        booking_data.GetUserId() === user_phone_number ||
                        GetUserIdFromBookingRefStr(booking_data.reference) === user_phone_number)
                ) {
                    SetShowUnauthorizedAccess(false); // Just in case it was enabled due to some unexpected state-change
                    const u = user_doc.data();
                    let b = booking_doc.data();
                    b["user_name"] = u.name;
                    b["user_type"] = u.is_permanent_member ? "member" : "others";
                    SetPaymentRef(b.payment_ref ? b.payment_ref : payment_ref);
                    SetBookingObj(b); // Booking object will get set
                    if (GetUserIdFromBookingRefStr(booking_data.reference) === user_phone_number) {
                        SetRequestApprovalMode(true);
                    }
                } else {
                    console.warn("Preventing unauthorized access");
                    SetShowUnauthorizedAccess(true);
                    setTimeout(() => {
                        navigate(-1);
                    }, 2000);
                }
            }

            SetFetchingBookingObj(false);
        };

        if (!isFetching && Object.keys(user_data).length > 0) {
            GetBookingDetails();
        }
    }, [user_data, user_phone_number, isFetching]);

    useEffect(() => {
        if (!current_time) return;

        const interval = setInterval(() => {
            const t = current_time;
            SetCurrentTime(new Date(t.setSeconds(t.getSeconds() + 1)));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!booking_obj?.modified_by) return;

        const GetAdminActionHistory = async () => {
            let history = [];
            const history_data = booking_obj.modified_by;
            for (let idx = 0; idx < history_data.length; idx++) {
                const user_doc = await getDoc(history_data[idx].modifier);
                const user_obj = user_doc.exists() ? user_doc.data() : {};
                history.push({
                    ...history_data[idx],
                    ...user_obj,
                    id: idx
                });
            }

            SetAdminActionHistory(history);
        };

        if (admin_view) GetAdminActionHistory();
    }, [booking_obj?.modified_by?.length]);

    const HandleBookingDeletion = async () => {
        SetBookingDeletionInProgress(true);
        const result = await booking_obj.DeleteDoc();
        setTimeout(() => {
            SetBookingDeletionInProgress(false);
            SetShowDeleteConfirmation(false);
            if (result.status) {
                SetBookingObj(null);
            } else {
                const message = "Could not accept this booking (" + result.message + ")";
                toast.error(message);
            }
        }, 1000);
    };

    const HandleBookingAcceptance = async () => {
        SetBookingAcceptanceInProgress(true);
        const result = await booking_obj.SetBookingStatus(Constants.STATUS_CONFIRMED, user_phone_number);
        setTimeout(() => {
            SetBookingAcceptanceInProgress(false);
            SetShowAcceptBookingRequest(false);
            if (!result.status) {
                const message = "Could not accept this booking (" + result.message + ")";
                toast.error(message);
            }
        }, 1000);
    };

    const HandleBookingRejection = async () => {
        if (rejectionNote?.length > 0) {
            setError(false);
        } else {
            setError(true);
            return;
        }

        const result = await booking_obj.SetBookingStatus(Constants.STATUS_REJECTED, user_phone_number, rejectionNote);
        setTimeout(() => {
            SetShowCancelBookingRequest(false);
            if (!result.status) {
                const message = "Could not accept this booking (" + result.message + ")";
                toast.error(message);
            }
        }, 1000);
    };

    const [show_reference_approve_booking_request, SetShowReferenceApproveBookingRequest] = useState(false);
    const [booking_reference_approval_in_progress, SetBookingReferenceApprovalInProgress] = useState(false);
    const HandleBookingReferenceApproval = async () => {
        SetBookingReferenceApprovalInProgress(true);
        const result = await booking_obj.ApproveBookingRequestFromReference();
        setTimeout(() => {
            SetBookingReferenceApprovalInProgress(false);
            SetShowReferenceApproveBookingRequest(false);
            if (!result.status) {
                const message = "Could not accept this booking (" + result.message + ")";
                toast.error(message);
            }
        }, 1000);
    };

    const [payment_ref_edit_mode, SetPaymentRefEditMode] = useState(false);

    if (isFetching || user_data == null || fetching_booking_obj) {
        return <LoadingAnimation />;
    } else if (!booking_obj && !fetching_booking_obj) {
        return (
            <Card className="mt-5 text-center">
                <div className="flex flex-col items-center p-10 gap-10">
                    <div className="tracking-wide text-2xl">Booking has been deleted or does not exist</div>
                    <div className="flex flex-row gap-3">
                        {IsAdmin() && (
                            <Link to="/admin/bookings">
                                <Button>all bookings</Button>
                            </Link>
                        )}
                        <Link to="/dashboard/bookings">
                            <Button>my bookings</Button>
                        </Link>
                    </div>
                </div>
            </Card>
        );
    }

    const slots_booked = booking_obj?.GetSlotsBooked();
    const event = booking_obj?.GetEventType();
    const refundable_deposit_cost = booking_obj?.GetRefundableDeposit();
    const user_type = booking_obj?.user_type;

    const GetRemainingProvisionalTime = created_on => {
        if (!created_on || !current_time) return;

        let end_time = clone(created_on);
        end_time.setDate(end_time.getDate() + Constants.PROVISIONAL_NUM_OF_DAYS);
        end_time.setHours(23);
        end_time.setMinutes(59);
        end_time.setSeconds(59);
        const time_remaining = end_time.getTime() - current_time.getTime();
        const d = Math.floor(time_remaining / (1000 * 60 * 60 * 24));
        const h = Math.floor((time_remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const m = Math.floor((time_remaining % (1000 * 60 * 60)) / (1000 * 60));

        return `${d} days, ${h} hrs. ${m} mins.`;
    };

    const show_reference_approval_note = booking_obj && booking_obj.HasReference() && !booking_obj.IsApprovedByBlockReference();
    const show_general_approval_note = booking_obj && booking_obj.IsRequest();
    const rejection_reason = booking_obj && booking_obj.GetRejectionReason() && booking_obj.IsRejected();
    const show_note = show_reference_approval_note || show_general_approval_note || rejection_reason;

    const SaveNewPaymentRefNumber = async payment_ref_num => {
        if (!booking_obj) return;

        payment_ref_num = payment_ref_num?.trim();
        if (!payment_ref_num) {
            toast.error("Payment reference number cannot be blank");
            return;
        }

        const res = await booking_obj.WriteNewPaymentRefNumber(payment_ref_num);
        if (res.status) {
            toast("Payment reference number has been saved");
        } else {
            toast.error(`Payment reference number could not be saved [${res.message}]`);
        }
    };

    return (
        <>
            <div className="w-full">
                <Card className="mt-5">
                    <Card.Body>
                        <div>
                            <div>
                                {show_unauthorized_access ? (
                                    <Alert color="failure">
                                        <span>
                                            {/* TODO: Do not make an empty threat, record this activity into 'system' */}
                                            <span className="font-bold">Unauthorized access!</span> This will be reported
                                        </span>
                                    </Alert>
                                ) : null}

                                {booking_obj === null ? (
                                    <div className="w-full text-center">
                                        <Spinner size="xl" />
                                    </div>
                                ) : slots_booked && event && refundable_deposit_cost && user_type ? (
                                    <div className="px-3 py-5 sm:px-5">
                                        <div className="flex flex-col md:flex-row-reverse md:justify-between mb-5">
                                            <div className="flex flex-row text-center justify-center xs:justify-end tracking-wide text-2xl sm:text-3xl text-slate-500 mb-4">
                                                Community hall booking slip
                                            </div>
                                            <div className="flex flex-row gap-x-2 justify-end">
                                                {booking_obj.HasReference() ? (
                                                    <Tooltip
                                                        content={
                                                            booking_obj.IsApprovedByBlockReference()
                                                                ? `approval received from ${booking_obj.GetReferenceName()}`
                                                                : `requires an approval from ${booking_obj.GetReferenceName()}`
                                                        }
                                                        placement="bottom"
                                                    >
                                                        <Badge size="lg" color={booking_obj.IsApprovedByBlockReference() ? "success" : "warning"}>
                                                            {booking_obj.IsApprovedByBlockReference()
                                                                ? "block-member approved"
                                                                : "block-member approval pending"}
                                                        </Badge>
                                                    </Tooltip>
                                                ) : null}
                                                <Tooltip
                                                    content={
                                                        booking_obj.IsRequest()
                                                            ? "upon confirmation of payment, an administrator will approve this booking"
                                                            : booking_obj.IsConfirmed()
                                                            ? "administrator has accepted the booking"
                                                            : "booking was rejected by administrator"
                                                    }
                                                    placement="bottom"
                                                >
                                                    <Badge
                                                        size="lg"
                                                        color={booking_obj.IsRequest() ? "warning" : booking_obj.IsConfirmed() ? "success" : "failure"}
                                                    >
                                                        {booking_obj.IsRequest()
                                                            ? "provisional booking"
                                                            : booking_obj.IsConfirmed()
                                                            ? "confirmed booking"
                                                            : "booking has been rejected"}
                                                    </Badge>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="ml-3 mb-4 flex flex-col justify-start gap-y-2">
                                            <div className="font-mono flex flex-row justify-end tracking-wide text-slate-500 text-lg">
                                                <span className="text-cyan-700 hover:text-cyan-500 text-right">
                                                    <Link to="/community-hall-rules-and-regulations" target="_blank">
                                                        Community Centre rules and regulations
                                                    </Link>
                                                </span>
                                            </div>
                                            <div className="font-mono flex flex-row justify-end tracking-wide text-slate-500 text-sm xs:text-base">
                                                <span className="lowercase font-bold mr-4">booking reference</span>
                                                <span className="text-black">{booking_obj.GetBookingCode()}</span>
                                            </div>
                                            <div className="font-mono flex flex-row justify-end lowercase tracking-wide text-slate-500 text-sm xs:text-base">
                                                <span className="font-bold mr-4">booking created on</span>
                                                <span className="text-black">{booking_obj.GetCreatedOnString()}</span>
                                            </div>
                                            {booking_obj.reference ? (
                                                <div className="font-mono flex flex-row justify-end tracking-wide text-slate-500 text-sm xs:text-base">
                                                    <span className="lowercase font-bold mr-4">reference of</span>
                                                    <span className="text-black">{GetUserNameFromBookingRefStr(booking_obj.reference)}</span>
                                                </div>
                                            ) : null}
                                            {booking_obj.reference_user_email && (
                                                <div className="text-right">
                                                    <div className="font-mono flex flex-col justify-center xs:justify-end tracking-wide text-slate-500 text-sm xs:text-base">
                                                        <span className="lowercase font-bold">reference member's email</span>
                                                        <span className="text-black">{booking_obj.reference_user_email}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {booking_obj.reference && booking_obj.IsApprovedByBlockReference() ? (
                                                <div className="font-mono flex flex-row justify-center xs:justify-end tracking-wide text-slate-500 text-sm xs:text-base">
                                                    <span className="lowercase font-bold mr-4">reference approval received on </span>
                                                    <span className="text-black">{booking_obj.GetApprovalTimestampStr()}</span>
                                                </div>
                                            ) : null}
                                            {payment_ref || (!payment_ref && admin_view) ? (
                                                <div className="font-mono flex flex-row items-center justify-end lowercase tracking-wide text-slate-500 text-sm xs:text-base">
                                                    <span className="font-bold mr-4">payment reference</span>
                                                    <span className="text-black">
                                                        {admin_view ? (
                                                            <div className="flex flex-row gap-x-2">
                                                                <TextInput
                                                                    color="gray"
                                                                    disabled={!payment_ref_edit_mode}
                                                                    sizing="sm"
                                                                    onChange={e => {
                                                                        SetPaymentRef(e.target.value);
                                                                    }}
                                                                    value={payment_ref}
                                                                />
                                                                {payment_ref_edit_mode ? (
                                                                    <Button
                                                                        className="flex flex-col items-center"
                                                                        color="light"
                                                                        size="sm"
                                                                        onClick={e => {
                                                                            SaveNewPaymentRefNumber(payment_ref);
                                                                            SetPaymentRefEditMode(false);
                                                                        }}
                                                                    >
                                                                        <FaRegCheckCircle />
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        className="flex flex-col items-center"
                                                                        color="light"
                                                                        size="sm"
                                                                        onClick={e => {
                                                                            SetPaymentRefEditMode(true);
                                                                        }}
                                                                    >
                                                                        <FaRegEdit />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            payment_ref
                                                        )}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="ml-3 mb-14 flex flex-col justify-start gap-y-4">
                                            <div className="flex flex-row justify-start tracking-wide text-slate-500 text-sm xs:text-base">
                                                <span className="lowercase tracking-wide lowercase font-bold mr-4">booking for</span>
                                                <span>{booking_obj.user_name}</span>
                                            </div>
                                            {booking_obj.user_email && (
                                                <div className="flex flex-row justify-start tracking-wide text-slate-500 text-sm xs:text-base">
                                                    <span className="lowercase tracking-wide lowercase font-bold mr-4">email</span>
                                                    <span>{booking_obj.user_email}</span>
                                                </div>
                                            )}
                                            <div className="flex flex-row justify-start tracking-wide text-slate-500 text-sm xs:text-base">
                                                <span className="lowercase tracking-wide lowercase font-bold mr-4">date range</span>
                                                <span>
                                                    {booking_obj.GetStartDateString()} to {booking_obj.GetEndDateString()}
                                                </span>
                                            </div>
                                            <div className="flex flex-row justify-start tracking-wide text-slate-500 text-sm xs:text-base">
                                                <span className="lowercase tracking-wide lowercase font-bold mr-4">purpose</span>
                                                <span>{booking_obj.GetEventString()}</span>
                                            </div>
                                            <div className="flex flex-row justify-start tracking-wide text-slate-500 text-sm xs:text-base">
                                                <span className="lowercase tracking-wide lowercase font-bold mr-4">details</span>
                                                <div className="flex flex-col">
                                                    {booking_obj
                                                        .GetEventDescription()
                                                        .split("\n")
                                                        .map(s => (
                                                            <span key={Math.random()}>{s}</span>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                        <PriceSummary
                                            user_type={user_type}
                                            event={event}
                                            slots_booked={slots_booked}
                                            refundable_deposit_cost={refundable_deposit_cost}
                                        />
                                        <hr />
                                        {show_note ? (
                                            <div className="mt-14 flex flex-col font-mono">
                                                <span className="uppercase text-red-500 text-2xl">Note</span>
                                                <span>
                                                    <ul className="list-disc px-5 py-3">
                                                        {show_reference_approval_note ? (
                                                            <li>{`Before confirmation from the block-committee, ${booking_obj.GetReferenceName()} (${booking_obj.GetReferenceId()}) needs to approve this request.`}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {show_general_approval_note ? (
                                                            <li>
                                                                This is a provisional booking. Please contact the block-committee for payment details. <br />
                                                                Failure to pay within {GetRemainingProvisionalTime(booking_obj.GetCreatedOn())} will result in
                                                                rejection of booking.
                                                            </li>
                                                        ) : null}
                                                        {rejection_reason && (
                                                            <li>
                                                                <span className="font-bold">
                                                                    Reason for rejection of this booking:
                                                                    <br />
                                                                </span>
                                                                {booking_obj.GetRejectionReason()}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        {booking_obj === null || Object.keys(booking_obj).length === 0 ? null : (
                            <div className="py-2 flex flex-col gap-x-2 gap-y-2 sm:flex-row justify-end lowercase text-black tracking-wide">
                                {admin_view ? (
                                    <>
                                        {booking_obj.IsRequest() || booking_obj.IsRejected() ? (
                                            <Button
                                                size="sm"
                                                color="success"
                                                onClick={() => {
                                                    SetShowAcceptBookingRequest(true);
                                                }}
                                            >
                                                accept booking request
                                            </Button>
                                        ) : null}
                                        {(booking_obj.IsRequest() || booking_obj.IsConfirmed()) && admin_view ? (
                                            <Button
                                                size="sm"
                                                color="failure"
                                                onClick={() => {
                                                    SetShowCancelBookingRequest(true);
                                                }}
                                            >
                                                reject booking request
                                            </Button>
                                        ) : null}
                                    </>
                                ) : null}
                                {(admin_view || booking_obj.GetUserId() === user_phone_number) && (
                                    <Button
                                        size="sm"
                                        color="failure"
                                        onClick={() => {
                                            SetShowDeleteConfirmation(true);
                                        }}
                                    >
                                        delete booking
                                    </Button>
                                )}

                                {request_approval_mode && !booking_obj.IsApprovedByBlockReference() ? (
                                    <Tooltip placement="top" content="provide approval as the reference member">
                                        <Button
                                            className="w-100"
                                            size="sm"
                                            color="success"
                                            onClick={() => {
                                                SetShowReferenceApproveBookingRequest(true);
                                            }}
                                        >
                                            confirm booking
                                        </Button>
                                    </Tooltip>
                                ) : null}

                                <Button
                                    size="sm"
                                    onClick={() => {
                                        Booking.SendEmail(booking_obj);
                                    }}
                                >
                                    resend booking summary emails
                                </Button>
                            </div>
                        )}
                    </Card.Footer>
                </Card>
                {admin_view && admin_action_history.length > 0 && (
                    <Card className="mt-2">
                        <Card.Body>
                            <Card.Title>Action record of administrators</Card.Title>
                            <div>
                                {/* {admin_action_history === null || admin_action_history.length === 0
                ? null
                : admin_action_history.map((item) => {
                  const date = new Date(item.timestamp.seconds * 1000);
                  
                    return <div>{date.toDateString()}</div>;
                  })} */}
                            </div>
                            {admin_view ? (
                                <DataGrid
                                    rows={admin_action_history}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 }
                                        },
                                        density: "standard"
                                    }}
                                    pageSizeOptions={[10, 15, 20]}
                                    disableRowSelectionOnClick
                                    disableDensitySelector
                                    getRowHeight={() => "auto"}
                                    getEstimatedRowHeight={() => 400}
                                />
                            ) : (
                                <DataGrid rows={admin_action_history} columns={columns} disableRowSelectionOnClick disableDensitySelector />
                            )}
                        </Card.Body>
                    </Card>
                )}
            </div>

            {/* Cancel booking request */}
            <Modal
                show={show_delete_confirmation}
                size="md"
                popup="true"
                onClose={() => {
                    SetShowDeleteConfirmation(false);
                }}
            >
                <Modal.Header />

                <Modal.Body>
                    {booking_deletion_in_progress === false ? (
                        <div className="text-center">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Confirm deleting this booking request</h3>
                            <div className="flex justify-center gap-4">
                                <Button color="failure" onClick={HandleBookingDeletion}>
                                    Yes, I'm sure
                                </Button>
                                <Button
                                    color="gray"
                                    onClick={() => {
                                        SetShowDeleteConfirmation(false);
                                    }}
                                >
                                    No, don't delete
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col text-center">
                            <span className="mb-5 text-lg">Deleting booking request</span>
                            <div className="text-center">
                                <Spinner color="info" size="xl" />
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                show={show_accept_booking_request}
                size="md"
                popup="true"
                onClose={() => {
                    SetShowAcceptBookingRequest(false);
                }}
            >
                <Modal.Header />
                <Modal.Body>
                    {booking_acceptance_in_progress === false ? (
                        <div className="text-center">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Sure you want to accept this booking request?</h3>
                            <div className="flex justify-center gap-4">
                                <Button color="success" onClick={HandleBookingAcceptance}>
                                    Yes, I'm sure
                                </Button>
                                <Button
                                    color="gray"
                                    onClick={() => {
                                        SetShowAcceptBookingRequest(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col text-center">
                            <span className="mb-5 text-lg">Accepting booking request</span>
                            <Spinner color="success" size="xl" />
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                show={show_cancel_booking_request}
                size="md"
                popup="true"
                onHide={() => {
                    SetShowCancelBookingRequest(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reject Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Rejection reason</Form.Label>
                            <Form.Control as="textarea" rows={3} value={rejectionNote} onChange={e => setRejectionNote(e.target.value)} />
                            <Form.Text className="text-danger">{error && <div className="text-danger">Please enter a reason</div>}</Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setError(false);
                            SetShowCancelBookingRequest(false);
                            setRejectionNote("");
                        }}
                    >
                        No, don't cancel
                    </Button>
                    <Button variant="primary" onClick={HandleBookingRejection}>
                        Yes, cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={show_reference_approve_booking_request}
                size="md"
                popup="true"
                onClose={() => {
                    SetShowReferenceApproveBookingRequest(false);
                }}
            >
                <Modal.Header />
                <Modal.Body>
                    {booking_reference_approval_in_progress === false ? (
                        <div className="text-center">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure that you want to confirm this booking? <br />
                                Please note that once confirmed, you will not be able to undo this.
                            </h3>
                            <div className="flex justify-center gap-4">
                                <Button color="success" onClick={HandleBookingReferenceApproval}>
                                    Yes, I'm sure
                                </Button>
                                <Button
                                    color="gray"
                                    onClick={() => {
                                        SetShowReferenceApproveBookingRequest(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col text-center">
                            <span className="mb-5 text-lg">Approving this booking from your end</span>
                            <div className="text-center">
                                <Spinner color="info" size="xl" />
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewBooking;
