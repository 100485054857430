import { getStorage, deleteObject, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Button, Label, Textarea, TextInput, FileInput, Card } from "flowbite-react";
import { Form, Formik } from "formik";
import * as yup from "yup";

import Notice from "../helpers/Notice";
import globals from "../helpers/globals.json";
import { StdContext } from "../context/StdContext";

function UploadNotice() {
    const max_notice_file_size_in_mb = globals.Constants.MAX_NOTICE_FILE_SIZE / 1000000;
    const supported_file_formats = ["application/pdf"];
    const schema = yup.object().shape({
        title: yup.string().required("A title is required"),
        description: yup.string().required("A short description about the notice is required"),
        notice_file: yup
            .mixed()
            .required("Please upload a notice file")
            .test("fileSize", `File size cannot be larger than ${max_notice_file_size_in_mb}MB`, value => value.size <= globals.Constants.MAX_NOTICE_FILE_SIZE)
            .test("fileType", "Unsupported file format", value => supported_file_formats.includes(value.type))
    });

    const std_context = useContext(StdContext);
    const navigate = useNavigate();
    const HandleSubmit = async (values, { setSubmitting }) => {
        const storage = getStorage();
        try {
            const notice_board_storage_ref = ref(storage, `notice-board/${values.notice_file.name}`);
            const upload_result = await uploadBytes(notice_board_storage_ref, values.notice_file);
            const download_url = await getDownloadURL(notice_board_storage_ref);
            const create_doc_status = await Notice.CreateDoc({
                created_by: std_context.user_phone_number,
                created_on: upload_result.metadata.timeCreated,
                gs_bucket: upload_result.metadata.bucket,
                full_file_path: upload_result.metadata.fullPath,
                download_url: download_url,
                title: values.title,
                description: values.description
            });

            if (create_doc_status.status) {
                toast.success("New notice has been uploaded");
                navigate(`/notice-board/view-notice?id=${create_doc_status.data.Id()}`);
            } else {
                // If the database could not be updated, delete the file
                await deleteObject(notice_board_storage_ref);
                toast.error(`Could not create the notice (${create_doc_status.message})`);
            }
        } catch (err) {
            toast.error(`Could not create the notice (SYSTEM-ERROR: ${err})`);
        }
    };

    return (
        <>
            <Helmet>
                <title>Upload Notice | fdblock.org</title>
            </Helmet>
            <div className="w-full flex flex-row items-center justify-center">
                <Card className="px-4 py-2 mt-10 max-w-lg">
                    <div className="flex flex-col gap-3">
                        <h3 className="flex justify-center items-center mb-3 lowercase text-black text-4xl 2xl:text-5xl font-semibold">Upload new notice</h3>
                        <Formik initialValues={{ title: "", description: "", notice_file: undefined }} validationSchema={schema} onSubmit={HandleSubmit}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <div className="flex flex-col gap-3">
                                        <div className="">
                                            <FileInput
                                                name="notice_file"
                                                helperText={`PDF (maximum file size: ${max_notice_file_size_in_mb}MB).`}
                                                accept=".pdf"
                                                onChange={event => {
                                                    setFieldValue("notice_file", event.target.files[0]);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {touched.notice_file && errors.notice_file && <div className="text-red-600">{errors.notice_file}</div>}
                                        </div>
                                        <div className="flex flex-col justify-start">
                                            <Label htmlFor="title" className="text-lg mb-1">
                                                Notice title
                                            </Label>
                                            <TextInput
                                                id="title"
                                                name="title"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                                helperText={touched.title && errors.title && <div className="text-danger">{errors.title}</div>}
                                            />
                                        </div>
                                        <div className="flex flex-col justify-start">
                                            <Label htmlFor="description" className="text-lg mb-1">
                                                Short description
                                            </Label>
                                            <Textarea
                                                id="description"
                                                rows={4}
                                                name="description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.description}
                                                helperText={
                                                    touched.description && errors.description && <div className="text-danger">{errors.description}</div>
                                                }
                                            />
                                        </div>
                                        <Button type="submit" className="lowercase" size="sm" disabled={isSubmitting}>
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default UploadNotice;
