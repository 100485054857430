import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tabs, Table, Spinner } from "flowbite-react";

import { getDoc } from "firebase/firestore";

import { StdContext } from "../../context/StdContext";
import { UserCacheContext } from "../../context/UserCacheContext";
import Booking from "../../helpers/Booking";

const globals = require("../../helpers/globals.json");
const Constants = globals.Constants;
const Collections = globals.Collections;

const ListBookings = ({ list_requests = false, uid = null }) => {
    const { NoData, user_phone_number, IsAdmin } = useContext(StdContext);
    const user_info_context = useContext(UserCacheContext);
    const [bookings, SetBookings] = useState(null);

    // On obtaining the user_id from context, fetch the user's booking data-set
    useEffect(() => {
        if (NoData()) return;

        const FetchBookingData = async Populate => {
            let phone_number_to_consider = user_phone_number;
            if (uid && IsAdmin()) {
                phone_number_to_consider = "+" + uid.trim();
            }

            const user_data = await user_info_context.GetUser(phone_number_to_consider);
            if (!user_data) {
                SetBookings({});
                return;
            }

            let booking_data_set = [];
            const bookings = list_requests ? user_data.booking_approval_requests : user_data.bookings;
            if (bookings === undefined) {
                SetBookings({});
                return;
            }

            // Not using forEach since it sort of runs asynchronously, which leads to booking_data_set being empty
            // Not sure why this happens
            for (let idx = 0; idx < bookings.length; idx++) {
                const booking_doc = await getDoc(bookings[idx].withConverter(Booking.FirestoreConverter));
                if (booking_doc.exists()) {
                    const booking_obj = booking_doc.data();
                    booking_data_set.push(booking_obj);
                }
            }

            SetBookings(booking_data_set);
        };

        FetchBookingData();
    }, []);

    const CreateTableHeader = (show_status = true) => {
        return (
            <>
                <Table.HeadCell>#</Table.HeadCell>
                <Table.HeadCell>Booking start</Table.HeadCell>
                <Table.HeadCell>Booking end</Table.HeadCell>
                <Table.HeadCell>{list_requests ? "Booking made by" : "Purpose"}</Table.HeadCell>
                {show_status === true ? <Table.HeadCell>Status</Table.HeadCell> : null}
                <Table.HeadCell></Table.HeadCell>
            </>
        );
    };

    const REQUESTS = Constants.STATUS_REQUEST;
    const UPCOMING = "upcoming";
    const PAST = "past";
    const CreateBookingsRows = (filter = null) => {
        if (bookings === null) return null;

        let rows = [];
        for (let i = 0; i < bookings.length; i++) {
            const booking_obj = bookings[i];
            if (list_requests) {
                if (filter === REQUESTS && booking_obj.IsApprovedByBlockReference()) continue;
                if (filter === PAST && !booking_obj.IsApprovedByBlockReference()) continue;
            } else {
                const is_request = booking_obj.IsRejected() || booking_obj.IsRequest();
                if (filter === REQUESTS && !is_request) continue;
                if (filter === PAST && (is_request || !booking_obj.IsPast())) continue;
                if (filter === UPCOMING && (is_request || !booking_obj.IsUpcoming())) continue;
            }

            rows.push(
                <Table.Row key={booking_obj.id}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{booking_obj.GetStartDateString()}</Table.Cell>
                    <Table.Cell>{booking_obj.GetEndDateString()}</Table.Cell>
                    <Table.Cell>{list_requests ? booking_obj.GetReferenceName() : booking_obj.GetEventString()}</Table.Cell>
                    {!list_requests && (filter === null || filter === REQUESTS) ? <Table.Cell>{booking_obj.GetStatusString()}</Table.Cell> : null}
                    <Table.Cell>
                        <Link to={`/dashboard/bookings/view-booking?id=${booking_obj.id}`}>
                            <span className="text-blue-500">View</span>
                        </Link>
                    </Table.Cell>
                </Table.Row>
            );
        }

        return rows;
    };

    return (
        <div>
            <div className="h-full">
                {bookings === null ? (
                    <div className="w-full text-center">
                        <Spinner />
                    </div>
                ) : list_requests ? (
                    <Tabs style="underline">
                        <Tabs.Item active={true} title="Approval Required">
                            <Table>
                                <Table.Head>{CreateTableHeader(false)}</Table.Head>
                                <Table.Body>{CreateBookingsRows(REQUESTS)}</Table.Body>
                            </Table>
                        </Tabs.Item>
                        <Tabs.Item title="Approval Given">
                            <Table>
                                <Table.Head>{CreateTableHeader(false)}</Table.Head>
                                <Table.Body>{CreateBookingsRows(PAST)}</Table.Body>
                            </Table>
                        </Tabs.Item>
                    </Tabs>
                ) : (
                    <Tabs style="underline">
                        <Tabs.Item active={true} title="All">
                            <div className="overflow-auto">
                                <Table>
                                    <Table.Head>{CreateTableHeader()}</Table.Head>
                                    <Table.Body>{CreateBookingsRows()}</Table.Body>
                                </Table>
                            </div>
                        </Tabs.Item>
                        <Tabs.Item title="Requests">
                            <div className="overflow-auto">
                                <Table>
                                    <Table.Head>{CreateTableHeader()}</Table.Head>
                                    <Table.Body>{CreateBookingsRows(REQUESTS)}</Table.Body>
                                </Table>
                            </div>
                        </Tabs.Item>
                        <Tabs.Item title="Upcoming">
                            <div className="overflow-auto">
                                <Table>
                                    <Table.Head>{CreateTableHeader(false)}</Table.Head>
                                    <Table.Body>{CreateBookingsRows(UPCOMING)}</Table.Body>
                                </Table>
                            </div>
                        </Tabs.Item>
                        <Tabs.Item title="Past">
                            <div className="overflow-auto">
                                <Table>
                                    <Table.Head>{CreateTableHeader(false)}</Table.Head>
                                    <Table.Body>{CreateBookingsRows(PAST)}</Table.Body>
                                </Table>
                            </div>
                        </Tabs.Item>
                    </Tabs>
                )}
            </div>
        </div>
    );
};

export default ListBookings;
