import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Modal, Tooltip, Button, Card, Table } from "flowbite-react";
import { FaShareAlt } from "react-icons/fa";
import { toast } from "react-toastify";

import LoadingAnimation from "../components/LoadingAnimation";
import { NoticeCacheContext } from "../context/NoticeCacheContext";
import { StdContext } from "../context/StdContext";
import { DateToString } from "../helpers/utils";

function ViewNotice() {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const notice_id = searchParams.get("id");
    const notice_cache_context = useContext(NoticeCacheContext);
    const std_context = useContext(StdContext);
    const [notice_data, SetNoticeData] = useState(null);
    useEffect(() => {
        const GetNoticeData = async () => {
            const data = await notice_cache_context.GetNotice(notice_id);
            if (data) {
                SetNoticeData(data);
            } else {
                SetNoticeData({});
            }
        };

        GetNoticeData();
    }, []);

    const [show_confirm_delete, SetShowConfirmDelete] = useState(false);
    const [deletion_in_progress, SetDeletionInProgress] = useState(false);

    const HandleDeleteNotice = async () => {
        SetShowConfirmDelete(false);
        SetDeletionInProgress(true);
        const notice_title = notice_data.Title();
        const delete_status = await notice_cache_context.DeleteNotice(notice_data.Id());
        if (delete_status.status) {
            toast.success(`'${notice_title}' has been deleted`);
            navigate("/notice-board");
            SetNoticeData({});
            SetDeletionInProgress(false);
        } else {
            toast.error(`'${notice_title}' could not be deleted (SYSTEM-ERROR: ${delete_status.message})`);
        }
    };

    if (!notice_data) {
        return <LoadingAnimation />;
    } else if (Object.keys(notice_data).length === 0) {
        return (
            <Card className="mt-5 text-center">
                <div className="flex flex-col items-center p-10 gap-10">
                    <div className="tracking-wide text-2xl">Notice ({notice_id}) does not exist</div>
                </div>
            </Card>
        );
    }

    return (
        <>
            <Helmet>
                <title>View Notice | fdblock.org</title>
            </Helmet>
            <div className="w-full flex flex-row items-center justify-center">
                <Card className="px-4 pt-2 mt-10 max-w-lg">
                    <div className="flex gap-2 flex-col items-center pb-10">
                        <h3 className="flex gap-5 justify-center items-center mb-1 lowercase text-black text-4xl 2xl:text-6xl font-semibold">
                            <span>{notice_data.Title()}</span>
                            <Tooltip content="Share this notice" placement="bottom">
                                <Button
                                    outline
                                    size="sm"
                                    color="dark"
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${window.location.origin}${location.pathname}${location.search}${location.hash}`);
                                        toast.success("Link to this notice has been copied into your clipboard");
                                    }}
                                    className="text-gray-600 flex items-center text-md"
                                >
                                    <div className="flex flex-col items-center justify-center">
                                        <FaShareAlt />
                                        <span className="font-bold text-xs tracking-wide">share</span>
                                    </div>
                                </Button>
                            </Tooltip>
                        </h3>
                        <Table className="my-5">
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="text-lg font-semibold lowercase text-black">Uploaded on</Table.Cell>
                                    <Table.Cell>{DateToString(notice_data.CreatedOn())}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="text-lg font-semibold lowercase text-black">Description</Table.Cell>
                                    <Table.Cell className="flex flex-col items-start">
                                        {notice_data
                                            .Description()
                                            .split("\n")
                                            .map(s => (
                                                <span key={Math.random()}>{s}</span>
                                            ))}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Button as={Link} to={notice_data.DownloadURL()}>
                            Download
                        </Button>
                        {std_context.IsAdmin() && (
                            <>
                                <Button
                                    color="failure"
                                    onClick={() => {
                                        SetShowConfirmDelete(true);
                                    }}
                                    disabled={deletion_in_progress}
                                >
                                    Delete notice
                                </Button>
                            </>
                        )}
                    </div>
                </Card>
                <Modal popup show={show_confirm_delete} size={"sm"} onClose={() => SetShowConfirmDelete(false)}>
                    <div className="flex flex-col gap-4 p-4">
                        <div className="flex flex-col gap-2">
                            <span className="text-lg">Are you sure you want to delete this notice?</span>
                            <span className="text-sm text-red-500 text-center font-bold italic">This cannot be reverted</span>
                        </div>
                        <div className="flex flex-col gap-2">
                            <Button color="failure" onClick={HandleDeleteNotice}>
                                Yes
                            </Button>
                            <Button color="gray" onClick={() => SetShowConfirmDelete(false)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default ViewNotice;
