import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "flowbite-react";
import { Helmet } from "react-helmet-async";
import { FaArrowRight } from "react-icons/fa";

import FDBlockLandingImage from "../assets/images/fdblock-landing.webp";

export default function HomePage() {
    return (
        <>
            <Helmet>
                <title>Home | fdblock.org</title>
            </Helmet>
            <div className="w-full flex flex-row items-center justify-center">
                <Card className="flex justify-center align-center content-center mt-4 mb-4 p-0">
                    <header className="flex flex-col md:flex-row gap-5 justify-stretch">
                        <img
                            className="relative h-screen md:h-auto blur-[1px] brightness-[0.45] md:filter-none rounded-lg md:max-w-[50%]"
                            loading="lazy"
                            src={FDBlockLandingImage}
                            alt="fdblock.org landing image"
                        />
                        <div className="absolute md:relative -translate-x-2/4 -translate-y-2/4 md:transform-none top-2/4 left-2/4 md:top-auto md:left-auto w-full flex flex-col justify-center content-center items-center gap-5 text-gray-100 md:text-black text-5xl 2xl:text-6xl text-right font-semibold">
                            <div className="flex flex-col">
                                <span>welcome to</span>
                                <span>fdblock.org</span>
                            </div>
                            <div className="flex w-57 flex-col gap-2">
                                <Button color="gray" as={Link} to={"/new-booking"}>
                                    book our community hall
                                    <FaArrowRight className="ml-2 h-6 w-3" />
                                </Button>
                                <Button color="gray" as={Link} to={"/notice-board"}>
                                    view notice board
                                    <FaArrowRight className="ml-2 h-5 w-3" />
                                </Button>
                            </div>
                        </div>
                    </header>
                </Card>
            </div>
        </>
    );
}
