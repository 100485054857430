import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Table, Card, Button } from "flowbite-react";

import { StdContext } from "../context/StdContext";
import { NoticeCacheContext } from "../context/NoticeCacheContext";
import Notice from "../helpers/Notice";
import { DateToString } from "../helpers/utils";

function NoticeBoardPage() {
    const std_context = useContext(StdContext);
    const notice_cache_context = useContext(NoticeCacheContext);
    const admin_signed_in = std_context.SignedIn() && std_context.IsAdmin();
    const navigate = useNavigate();

    const [notices, SetNotices] = useState(null);
    useEffect(() => {
        const GetNotices = async () => {
            const notices = await Notice.GetDocsFromFirestore();
            if (notices.status) {
                SetNotices(notices.data);
                notices.data.forEach(data => {
                    notice_cache_context.UpdateNotice(data);
                });
            } else {
                SetNotices([]);
            }
        };

        GetNotices();
    }, []);

    const notice_board_header = ["Uploaded on", "Title", "View"];
    return (
        <>
            <Helmet>
                <title>Notice Board | fdblock.org</title>
            </Helmet>
            <div className="mt-4">
                <Card className="text-center">
                    <div className="flex flex-row items-center justify-around">
                        <div className="lowercase text-black text-4xl 2xl:text-6xl font-semibold text-center my-4">Notice Board</div>
                        {admin_signed_in && (
                            <Button
                                className="lowercase"
                                onClick={() => {
                                    navigate("/admin/upload-notice");
                                }}
                            >
                                Upload
                            </Button>
                        )}
                    </div>
                    <Table hoverable>
                        <Table.Head className="text-center">
                            {notice_board_header.map(h => (
                                <Table.HeadCell key={Math.random()}>{h}</Table.HeadCell>
                            ))}
                        </Table.Head>
                        <Table.Body>
                            {notices &&
                                Array.isArray(notices) &&
                                notices.map(c => {
                                    return (
                                        <Table.Row className="text-center" key={Math.random()}>
                                            <Table.Cell>{DateToString(c.CreatedOn())}</Table.Cell>
                                            <Table.Cell>{c.Title()}</Table.Cell>
                                            <Table.Cell>
                                                <Button size="xs" as={Link} to={`view-notice?id=${c.Id()}`}>
                                                    View
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                    </Table>
                </Card>
            </div>
        </>
    );
}

export default NoticeBoardPage;
