import React, { createContext, useState } from "react";
import Notice from "../helpers/Notice";

export const NoticeCacheContext = createContext();

export const NoticeCacheContextProvider = ({ children }) => {
    const [cached_notices, SetCachedNoticeInfo] = useState({});

    const GetNotice = async notice_id => {
        if (!notice_id) return;
        if (notice_id in cached_notices) {
            return cached_notices[notice_id];
        }

        const notice_data = await Notice.GetDataFromFirestore(notice_id);
        SetCachedNoticeInfo(prev_cached_notice => ({
            ...prev_cached_notice,
            [notice_id]: notice_data
        }));

        return notice_data; // The state might take some time to get updated, return the fetched data immediately
    };

    const UpdateNotice = notice => {
        if (!notice) return;

        SetCachedNoticeInfo(prev_cached_notice => ({
            ...prev_cached_notice,
            [notice.id]: notice
        }));
    };

    const DeleteNotice = async notice_id => {
        if (!notice_id) return;

        if (notice_id in cached_notices) {
            let temp_cache = { ...cached_notices };
            delete temp_cache[notice_id];
            SetCachedNoticeInfo(temp_cache);
        }

        return await Notice.DeleteNotice(notice_id);
    };

    return (
        <NoticeCacheContext.Provider
            value={{
                GetNotice: GetNotice,
                UpdateNotice: UpdateNotice,
                DeleteNotice: DeleteNotice
            }}
        >
            {children}
        </NoticeCacheContext.Provider>
    );
};

export default NoticeCacheContextProvider;
