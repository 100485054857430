import { getFirestore, collection, doc, setDoc, getDoc, getDocs, query, where, writeBatch, runTransaction, arrayUnion } from "firebase/firestore";

import * as Crypto from "crypto-js";

import app from "../firebase.config";
import { updateBlockDirOnMemberChange } from "./blockDir";
import Booking from "./Booking";

const globals = require("./globals.json");
const DocNames = globals.DocNames;
const Collections = globals.Collections;
const Constants = globals.Constants;

/*
 * * * * * * * * * * * * *
 * User object structure *
 * * * * * * * * * * * * *
 *     id: user_id (string),
 *     phone_number (tel-number),
 *     email (string),
 *     name (string),
 *     is_member (boolean),
 *     membership_status (boolean),
 *     address: {
 *         house_num: string
 *         street_name: string,
 *         city_name: string,
 *         pin_code: number,
 *         state: string,
 *         country: string
 *     },
 *     bookings: [],
 *     created_on: <Timestamp>
 */

class User {
    static LOCAL_STORAGE_USER_ITEM = "user_data";
    static KEY_ID = "id";
    static KEY_NAME = "name";
    static KEY_EMAIL = "email";
    static KEY_ADDRESS = "address";
    static KEY_PHONE_NUM = "phone_number";
    static KEY_IS_MEMBER = "is_member";
    static KEY_IS_PERMANENT_MEMBER = "is_permanent_member";
    static KEY_IS_ADMIN = "isAdmin";
    static KEY_BOOKINGS = "bookings";
    static KEY_BOOKING_APPROVAL_REQUESTS = "booking_approval_requests";
    static KEY_BOOKING_DATES_ATTEMPTED = "booking_dates_attempted";

    static IsLocalStorageUpdated = user_id => {
        const local_data = localStorage.getItem(this.LOCAL_STORAGE_USER_ITEM);
        if (local_data?.phone_number === user_id) return true;
        return false;
    };

    static GetUserObj = user_data => {
        if (!user_data) return null;

        const user = {
            name: user_data.name,
            phone_number: user_data.phone_number,
            address: user_data.address,
            is_member: user_data.is_member,
            is_permanent_member: user_data.is_permanent_member,
            membership_status: user_data.membership_status,
            created_on: user_data.created_on,
            isAdmin: user_data.isAdmin,
            plot: user_data.plot
        };

        return user;
    };

    static RestoreFromLocalStorage = () => {
        const local_data = localStorage.getItem(this.LOCAL_STORAGE_USER_ITEM);
        if (local_data) {
            // Restore encrypted user data
            const json_str = Crypto.AES.decrypt(local_data, process.env.REACT_APP_FD_LSKEY).toString(Crypto.enc.Utf8);
            return this.GetUserObj(JSON.parse(json_str));
        }

        return null;
    };

    static UpdateLocalStorage = user_data => {
        this.ClearLocalStorage();
        const local_data = this.GetUserObj(user_data);
        if (local_data) {
            // Encrypt and store user data
            const json_str = JSON.stringify(local_data);
            const data = Crypto.AES.encrypt(json_str, process.env.REACT_APP_FD_LSKEY).toString();
            localStorage.setItem(this.LOCAL_STORAGE_USER_ITEM, data);
        }
    };

    static ClearLocalStorage = () => {
        localStorage.removeItem(this.LOCAL_STORAGE_USER_ITEM);
    };

    static GetDataFromFirestore = async (user_id, fetch_all = false) => {
        if (!user_id) return null;

        const db = getFirestore(app);
        try {
            const user_ref = doc(db, Collections.USERS, user_id);
            console.debug("Querying data");
            const user_doc = await getDoc(user_ref);
            if (user_doc.exists()) {
                const d = user_doc.data();
                if (fetch_all) return d;
                return this.GetUserObj(d);
            }
        } catch (err) {
            console.error(err);
        }

        return null;
    };

    static FindExistingUserOrCreateNew = async user_phone_number => {
        if (!user_phone_number) return {};

        const db = getFirestore(app);
        const users_collection = collection(db, Collections.USERS);
        const q = query(users_collection, where("phone_number", "==", user_phone_number));
        const snapshots = await getDocs(q);
        if (snapshots.empty) {
            // Create a standard user document
            const new_user = {
                name: "",
                phone_number: user_phone_number,
                address: "",
                is_member: false,
                is_permanent_member: false,
                membership_status: false,
                created_on: new Date(),
                bookings: [],
                isAdmin: false
            };

            const db = getFirestore(app);
            const users_collection = doc(db, Collections.USERS, user_phone_number);
            try {
                await setDoc(users_collection, new_user);
                return new_user;
            } catch (err) {
                // TODO: Issue an alert
                console.error(err);
            }

            return {};
        }

        // This person's entry might have been inserted into the users collection by an administrator,
        // in the form of a general document (document's ID does not match with the user's ID). In such
        // a case, we have to set the user's document ID to the user ID.
        let user_doc_id = null;
        let user_doc_data = null;
        snapshots.forEach(doc => {
            console.warn("Querying data");
            if (doc.id === user_phone_number) {
                console.error("ASSERT");
                return {};
            }

            // It will be ensured that the document's ID in the "users" collection will always be
            // a Firebase generated user ID. In other cases (pre-filled docs, etc), we will fetch
            // the existing doc's data, create a new doc with the doc ID set to the user ID, and
            // remove the existing doc.
            user_doc_id = doc.phone_number;
            user_doc_data = doc.data();
        });

        // This will be a one time operation for preloaded user info ; this needs to be an atomic operation
        if (user_doc_id !== null) {
            try {
                const batch = writeBatch(db);
                const old_user_doc = doc(db, Collections.USERS, user_doc_id);
                const new_user_doc = doc(db, Collections.USERS, user_phone_number);
                user_doc_data["phone_number"] = user_phone_number;
                batch.set(new_user_doc, user_doc_data);
                batch.delete(old_user_doc);
                await batch.commit();
                return this.GetUserObj(user_doc_data);
            } catch (err) {
                console.error("Could not update existing user doc");
                console.error(err);
            }
        }

        return {};
    };

    static FindExistingUser = async user_phone_number => {
        if (!user_phone_number) return {};

        const db = getFirestore(app);
        const users_collection = collection(db, Collections.USERS);
        const q = query(users_collection, where("phone_number", "==", user_phone_number));
        const snapshots = await getDocs(q);
        if (snapshots.empty) {
            return false;
        } else {
            return true;
        }
    };

    static Update = async (user_id, user_obj, verify_member_status = false) => {
        const db = getFirestore(app);
        const user_ref = doc(db, Collections.USERS, user_obj.phone_number);
        try {
            await runTransaction(db, async transaction => {
                const user_doc = await transaction.get(user_ref);
                if (!user_doc.exists()) {
                    throw "User does not exist";
                }

                if (verify_member_status && user_obj[User.KEY_IS_MEMBER] && user_doc[User.KEY_IS_MEMBER] === false && user_doc[User.KEY_IS_MEMBER] === true) {
                    // THIS IS CURRENTLY NOT FUNCTIONING
                    user_obj[User.KEY_IS_MEMBER] = false; // The user will be marked as a member once an admin has verified
                    const residency_requests_ref = doc(db, Collections.SYSTEM, DocNames.RESIDENCY_REQUESTS);
                    await transaction.update(residency_requests_ref, {
                        [Constants.DATA]: arrayUnion(user_ref)
                    });
                }

                await transaction.update(user_ref, user_obj);
            });

            updateBlockDirOnMemberChange(user_obj);
        } catch (err) {
            console.error("Could not update user data");
            console.error(err);
        }
    };

    static MoveUser = async (old_phone_number, new_user_data) => {
        // Will be triggered in case of phone-number updation
        const db = getFirestore(app);
        const old_user_ref = doc(db, Collections.USERS, old_phone_number);
        const new_user_ref = doc(db, Collections.USERS, new_user_data.phone_number);
        await runTransaction(db, async transaction => {
            const old_user_doc = await transaction.get(old_user_ref);
            const old_user_data = old_user_doc.data();
            const new_user_id_str = Booking.CreateUserIdStr(new_user_data[User.KEY_NAME], new_user_data[User.KEY_PHONE_NUM]);

            // 1. Iterate through each of the user's bookings and update the user-id field on them
            const old_user_data_bookings = User.KEY_BOOKINGS in old_user_data ? old_user_data[User.KEY_BOOKINGS] : [];
            for (let i = 0; i < old_user_data_bookings.length; i++) {
                const booking_ref = old_user_data_bookings[i];
                let updated_user_id = {};
                updated_user_id[Booking.KEY_USER_ID] = new_user_id_str;
                await transaction.update(booking_ref, updated_user_id);
            }

            // 2. Iterate through each of the bookings which contains the user as a reference member and update the reference-id field on them
            const old_user_data_approval_requests =
                User.KEY_BOOKING_APPROVAL_REQUESTS in old_user_data ? old_user_data[User.KEY_BOOKING_APPROVAL_REQUESTS] : [];
            for (let i = 0; i < old_user_data_approval_requests.length; i++) {
                const booking_ref = old_user_data_approval_requests[i];
                let updated_reference_id = {};
                updated_reference_id[Booking.KEY_REFERENCE] = new_user_id_str;
                await transaction.update(booking_ref, updated_reference_id);
            }

            // 3. Move the (updated) old user's data to the new user's account
            await transaction.set(new_user_ref, { ...old_user_data, ...new_user_data });
            await transaction.delete(old_user_ref);
        });
    };

    static CreateNewUser = async user => {
        try {
            const user_phone_number = user.phone_number;
            const db = getFirestore(app);
            const users_collection = collection(db, Collections.USERS);
            const q = query(users_collection, where("phone_number", "==", user_phone_number));
            const snapshots = await getDocs(q);
            if (snapshots.empty) {
                const new_users_collection = doc(db, Collections.USERS, user_phone_number);
                await setDoc(new_users_collection, {
                    ...user,
                    membership_status: false,
                    created_on: new Date(),
                    bookings: [],
                    isAdmin: false
                });
                if (user.is_member) {
                    await updateBlockDirOnMemberChange(user);
                }

                return user;
            } else {
                throw "Phone number already taken";
            }
        } catch (e) {
            console.error(e);
        }
    };
}

export default User;
