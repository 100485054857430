import { Navigate, BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home.jsx";
import Login from "./pages/Login.jsx";
import { StdContextProvider } from "./context/StdContext.jsx";
import { UserCacheContextProvider } from "./context/UserCacheContext.jsx";
import { NoticeCacheContextProvider } from "./context/NoticeCacheContext.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth.jsx";
import NewBookingPage from "./pages/NewBooking.jsx";
import CompleteBooking from "./pages/CompleteBooking.jsx";
import ViewBooking from "./pages/ViewBooking.jsx";
import UserBookings from "./pages/UserBookings.jsx";
import Profile from "./pages/Profile.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import AllUsers from "./pages/AllUsers.jsx";
import AllBookings from "./pages/AllBookings.jsx";
import BookingRequests from "./pages/BookingRequests.jsx";
import BlockDir from "./pages/BlockDir.jsx";
import AddUser from "./pages/AddUser.jsx";
import PageNotFound404Component from "./pages/404.jsx";
import NoticeBoard from "./pages/NoticeBoard.jsx";
import UploadNotice from "./pages/UploadNotice.jsx";
import ViewNotice from "./pages/ViewNotice.jsx";
import RulesAndRegulations from "./pages/CommunityHallRulesAndRegulations.jsx";

function App() {
    return (
        <HelmetProvider>
            <div className="App">
                <StdContextProvider>
                    <UserCacheContextProvider>
                        <NoticeCacheContextProvider>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Layout />}>
                                        <Route path="404" element={<PageNotFound404Component />} />
                                        <Route element={<Home />} index />
                                        <Route path="login" element={<Login />} />
                                        <Route path="contact" element={<ContactUs />} />
                                        <Route path="about-us" element={<AboutUs />} />
                                        <Route path="notice-board" element={<NoticeBoard />} />
                                        <Route path="notice-board/view-notice" element={<ViewNotice />} />
                                        <Route path="new-booking" element={<NewBookingPage />} />
                                        <Route element={<RequireAuth />}>
                                            <Route path="new-booking/complete-booking" element={<CompleteBooking />} />

                                            <Route path="dashboard/bookings" element={<UserBookings />} />
                                            <Route path="dashboard/bookings/view-booking" element={<ViewBooking />} />
                                            <Route path="dashboard/booking-requests" element={<BookingRequests />} />

                                            <Route path="dashboard/profile" element={<Profile />} />
                                            <Route path="dashboard/block-directory" element={<BlockDir />} />

                                            <Route element={<RequireAuth require_admin_rights={true} />}>
                                                <Route path="admin/bookings" element={<AllBookings />} />
                                                <Route path="admin/bookings/view-booking" element={<ViewBooking />} />

                                                <Route path="admin/users" element={<AllUsers />} />
                                                <Route path="admin/users/view-user" element={<Profile />} />
                                                <Route path="admin/users/view-user-booking" element={<UserBookings />} />
                                                <Route path="admin/users/create-new" element={<AddUser />} />

                                                <Route path="admin/upload-notice" element={<UploadNotice />} />
                                            </Route>
                                        </Route>
                                        <Route path="community-hall-rules-and-regulations" element={<RulesAndRegulations />} />
                                    </Route>
                                    <Route path="*" element={<Navigate to="/404" replace />} />
                                </Routes>
                            </BrowserRouter>
                        </NoticeCacheContextProvider>
                    </UserCacheContextProvider>
                </StdContextProvider>
            </div>
        </HelmetProvider>
    );
}

export default App;
