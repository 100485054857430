import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { StdContext } from "../context/StdContext";
import { Button, Card, Table } from "flowbite-react";
import { Modal } from "react-bootstrap";
import UpdateUser from "../components/users/UpdateUser";
import User from "../helpers/User";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingAnimation from "../components/LoadingAnimation";

function Profile() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const uid = searchParams.get("uid");
    const [show, setShow] = useState(false);
    const [userData, setUserData] = useState(null);
    const { user_data, isFetching, user_phone_number } = useContext(StdContext);
    useEffect(() => {
        const getUser = async () => {
            if (uid) {
                let fetched_data = await User.GetDataFromFirestore("+" + uid.trim());
                if (!fetched_data) fetched_data = await User.GetDataFromFirestore(uid.trim());
                if (!fetched_data) fetched_data = {};
                setUserData(fetched_data);
            } else if (!isFetching && user_data != null) {
                setUserData(user_data);
            }
        };

        getUser();
    }, [isFetching, user_data, uid]);

    if (isFetching || userData == null) {
        return <LoadingAnimation />;
    } else if (userData !== null && Object.keys(userData).length === 0) {
        return (
            <Card className="mt-5 text-center">
                <div className="flex flex-col items-center p-10 gap-10">
                    <div className="tracking-wide text-2xl">User account does not exist</div>
                </div>
            </Card>
        );
    }

    return (
        <>
            <Helmet>
                <title>Profile | fdblock.org</title>
            </Helmet>
            <div className="w-full flex flex-row items-center justify-center">
                <Card className="px-4 pt-2 mt-10 max-w-lg">
                    <div className="flex flex-col items-center pb-10">
                        <div className="lowercase text-center text-black text-5xl 2xl:text-6xl font-semibold pt-10">{userData.name}</div>
                        <Table className="my-5">
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="text-black font-semibold lowercase text-xl">Address</Table.Cell>
                                    <Table.Cell>{userData.address}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="text-black font-semibold lowercase text-xl">Plot</Table.Cell>
                                    <Table.Cell>{userData.plot}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="text-black font-semibold lowercase text-xl">Phone number</Table.Cell>
                                    <Table.Cell>{userData.phone_number}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <div className="flex justify-end gap-2">
                            <Button
                                size="sm"
                                onClick={() => {
                                    setShow(true);
                                }}
                            >
                                Edit profile
                            </Button>
                            <Button
                                size="sm"
                                onClick={() => {
                                    if (user_data.isAdmin) {
                                        navigate(`/admin/users/view-user-booking?uid=${userData.phone_number}`);
                                    } else {
                                        navigate("/dashboard/bookings");
                                    }
                                }}
                            >
                                View Bookings
                            </Button>
                            {user_data.isAdmin && (
                                <Button
                                    size="sm"
                                    onClick={() => {
                                        navigate(`/new-booking/complete-booking?uid=${userData.phone_number}`);
                                    }}
                                >
                                    Create Booking
                                </Button>
                            )}
                        </div>
                    </div>
                </Card>
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>Edit profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateUser userData={userData} uid={user_phone_number} setShow={setShow} updateProfile isAdmin={user_data.isAdmin} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Profile;
