import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip, Button, Checkbox, Label, TextInput } from "flowbite-react";
import { useSearchParams } from "react-router-dom";

import { StdContext } from "../../context/StdContext";
import User from "../../helpers/User";
import { deleteFromBlockDir, updateBlockDirOnMemberChange } from "../../helpers/blockDir";

export function UpdateUserModal(addUser = false) {
    const [show, setShow] = useState(false);
    const [userData, setUserData] = useState();
    const { user_data, user_phone_number, isFetching } = useContext(StdContext);

    useEffect(() => {
        if (!isFetching && user_data?.phone_number) {
            setUserData(user_data);
        }
    }, [isFetching, user_data]);

    useEffect(() => {
        if (user_phone_number && (!userData?.name || !userData?.address)) {
            setShow(true);
        }
    }, [userData]);

    useEffect(() => {
        if (!user_data) {
            setShow(false);
        }
    }, [user_data]);

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Complete your profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateUser userData={userData} uid={user_phone_number} setShow={setShow} />
                </Modal.Body>
            </Modal>
        </>
    );
}

const UpdateUser = ({ userData, uid, setShow, updateProfile = false, isAdmin = "false" }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { user_phone_number, UpdateUserData, SignOut } = useContext(StdContext);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(false);
    const [phone_number_history, SetPhoneNumberHistory] = useState();
    const [step, setStep] = useState(1);
    const phoneRegExp = /^[\+][9][1][1-9]{1}[0-9]{9}$/;

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        address: yup.string().required("Address is required"),
        plot: yup.string(),
        ...(updateProfile && {
            phone_number: yup.string().required().matches(phoneRegExp, "Phone number is not valid (it should be like +91XXXXXXXXXX)")
        }),
        ...(isAdmin &&
            !updateProfile && {
                is_member: yup.boolean(),
                is_permanent_member: yup.boolean()
            })
    });

    const CreateNewUserFromExisting = async () => {
        setLoading(true);
        try {
            await User.MoveUser(phone_number_history.old, user);
        } catch (err) {
            toast.error(`Failed to migrate to new phone number (ERROR: ${err})`);
        }

        await deleteFromBlockDir(phone_number_history.old);
        await updateBlockDirOnMemberChange(user);
        setShow(false);
        setStep(1);
        setLoading(false);
        toast.success(`Your data has been moved to a new account under ${phone_number_history.new} ; you must login with the updated phone number`);

        if (uid === userData.phone_number) {
            // If the current user matches the user whose phone-number is being edited, logout the current user.
            // This will ensure that the user logs in again with the new phone-number.
            SignOut();
        } else {
            setSearchParams(`uid=${phone_number_history.new}`);
        }
    };

    return (
        <>
            {step === 1 && !loading && (
                <Formik
                    initialValues={{
                        name: userData?.name,
                        address: userData?.address,
                        plot: userData?.plot,
                        phone_number: userData?.phone_number,
                        is_member: userData?.is_member,
                        is_permanent_member: userData?.is_permanent_member
                    }}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting }) => {
                        const user = {
                            ...userData,
                            name: values.name || "",
                            address: values.address || "",
                            plot: values.plot || "",
                            ...(updateProfile && {
                                phone_number: values.phone_number || userData.phone_number
                            }),
                            is_member: values.is_member,
                            is_permanent_member: values.is_permanent_member
                        };

                        if (updateProfile && userData.phone_number !== values.phone_number && (await User.FindExistingUser(user.phone_number))) {
                            toast.error(`${values.phone_number} is already used`);
                        } else if (updateProfile && userData.phone_number !== values.phone_number) {
                            // Proceed to confirmation of change in phone number
                            setStep(2);
                            setUser(user);
                            SetPhoneNumberHistory({
                                new: user.phone_number,
                                old: userData.phone_number
                            });
                        } else {
                            // For all other fields, this block gets executed at once.
                            // NOTE: In case a phone-number is being updated 'CreateNewUserFromExisting' gets called
                            setLoading(true);
                            await User.Update(uid, user); // Handles block directory updation as well
                            await UpdateUserData(true); // Update the global state and cache
                            setShow(false);
                            setStep(1);
                            setLoading(false);
                            toast.success("New profile details have been saved!");
                        }
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <Form className="flex max-w-md flex-col gap-4">
                            <div className="max-w-md">
                                <div className="mb-2 block">
                                    <Label htmlFor="name" value="Name" />
                                </div>
                                <TextInput
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    helperText={touched.name && errors.name ? <div className="text-danger">{errors.name}</div> : null}
                                />
                            </div>

                            <div className="max-w-md">
                                <div className="mb-2 block">
                                    <Label htmlFor="address" value="Address" />
                                </div>
                                <TextInput
                                    type="text"
                                    name="address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    helperText={touched.address && errors.address ? <div className="text-danger">{errors.address}</div> : null}
                                />
                            </div>

                            {isAdmin && updateProfile && (
                                <div className="max-w-md">
                                    <div className="mb-2 block">
                                        <Label htmlFor="plot" value="Plot Number" />
                                    </div>
                                    <Tooltip content="Enable 'Community Member' for modifying this field">
                                        <TextInput
                                            type="text"
                                            name="plot"
                                            disabled={!values.is_member}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.plot}
                                            helperText={touched.plot && errors.plot ? <div className="text-danger">{errors.plot}</div> : null}
                                        />
                                    </Tooltip>
                                </div>
                            )}

                            {updateProfile && (
                                <div className="max-w-md">
                                    <div className="mb-2 block">
                                        <Label htmlFor="phone_number" value="Phone Number" />
                                    </div>
                                    <TextInput
                                        type="text"
                                        name="phone_number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone_number}
                                        helperText={
                                            touched.phone_number && errors.phone_number ? <div className="text-danger">{errors.phone_number}</div> : null
                                        }
                                    />
                                </div>
                            )}

                            {isAdmin && updateProfile && (
                                <div className="flex flex-col gap-2">
                                    <Label className="text-lg mb-2">Membership Details</Label>
                                    <div className="flex items-center gap-2">
                                        <Checkbox
                                            name="is_member"
                                            onBlur={handleBlur}
                                            checked={values.is_member}
                                            onChange={e => {
                                                e.preventDefault();
                                                if (!e.target.value) {
                                                    setFieldValue("is_permanent_member", false);
                                                }

                                                handleChange(e);
                                            }}
                                        />
                                        <Label>Community Member</Label>
                                    </div>
                                    <div className="flex gap-2">
                                        <Checkbox
                                            name="is_permanent_member"
                                            onBlur={handleBlur}
                                            checked={values.is_permanent_member}
                                            onChange={e => {
                                                e.preventDefault();
                                                if (e.target.value) {
                                                    setFieldValue("is_member", true);
                                                }

                                                handleChange(e);
                                            }}
                                        />
                                        <Label>
                                            Lifetime Member
                                            <div className="text-lime-700">Enabling this will show discounted booking prices for the user</div>
                                        </Label>
                                    </div>
                                </div>
                            )}

                            <div className="flex gap-1 justify-center">
                                <Button color="success" size="sm" type="submit" disabled={isSubmitting}>
                                    Save
                                </Button>
                                {updateProfile && (
                                    <Button
                                        size="sm"
                                        color="failure"
                                        onClick={() => {
                                            setShow(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            {step === 2 && (
                <div className="flex max-w-md flex-col gap-4">
                    <div className="text-md">
                        Are you sure you want to change {user_phone_number === phone_number_history.old ? "your" : "this"} phone number from <br />
                        <span className="text-red-600">{phone_number_history.old}</span> to <span className="text-green-700">{phone_number_history.new}</span> ?
                        <p className="mt-1 text-slate-400 text-sm italic">
                            Note: Once changed, {user_phone_number === phone_number_history.old ? "the user has" : "you have"} to login with the new phone
                            number (<span className="text-green-700">{phone_number_history.new}</span>)
                        </p>
                    </div>
                    <div className="flex flex-row gap-1 justify-center">
                        <Button.Group>
                            <Button
                                onClick={() => {
                                    setStep(3);
                                    CreateNewUserFromExisting();
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                color="failure"
                                size="sm"
                                onClick={() => {
                                    setShow(false);
                                    setTimeout(() => {
                                        setStep(1);
                                    }, 500);
                                }}
                            >
                                No
                            </Button>
                        </Button.Group>
                    </div>
                </div>
            )}
            {loading && <div>Updating.....</div>}
        </>
    );
};

export default UpdateUser;
