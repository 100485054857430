import React, { useState, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Card, Spinner, Button } from "flowbite-react";
import { FaArrowRight } from "react-icons/fa";

import CommunityHallImageSlider from "../components/SvgCommunityGroup";
import DatePicker from "../components/bookings/DatePicker";

export default function NewBookingPage() {
    const [start_date, SetStartDate] = useState(new Date());
    const [end_date, SetEndDate] = useState(new Date());
    const [disable_proceed, SetDisableProceed] = useState(true);
    const navigate = useNavigate();

    function Proceed(e) {
        const url_query = `?sd=${start_date.valueOf()}&ed=${end_date.valueOf()}`;
        navigate(`/new-booking/complete-booking${url_query}`);
    }

    return (
        <>
            <Helmet>
                <title>New Booking</title>
            </Helmet>
            <Card className="mt-10 pb-10 px-10">
                <div className="lowercase text-center text-black text-5xl 2xl:text-6xl font-semibold pt-10">Community Hall Booking</div>
                <div className="flex lg:flex-row flex-col align-center items-center">
                    <CommunityHallImageSlider className="max-w-[50%] lg:max-w-[100%]" />
                    <div className="flex flex-col items-center content-center justify-center w-full">
                        <div className="mb-14 mt-10 md:mt-40 md:scale-125">
                            <Suspense fallback={<Spinner color="success" aria-label="Success spinner example" />}>
                                <DatePicker
                                    StartDate={start_date}
                                    SetStartDate={SetStartDate}
                                    EndDate={end_date}
                                    SetEndDate={SetEndDate}
                                    SetDisableProceed={SetDisableProceed}
                                />
                            </Suspense>
                        </div>
                        <div className="flex justify-center w-full mt-20">
                            <Button disabled={disable_proceed} onClick={e => Proceed(e)}>
                                <span className="lowercase">Proceed</span>
                                <FaArrowRight className="ml-2 h-5 w-3" />
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}
