import { getFirestore, collection, doc, setDoc, getDoc, getDocs, deleteDoc } from "firebase/firestore";
import { getStorage, deleteObject, ref } from "firebase/storage";

import app from "../firebase.config";
import globals from "./globals.json";

class Notice {
    constructor(created_on, created_by, gs_bucket, full_file_path, download_url, title, description, id = null) {
        this.created_on = created_on;
        this.created_by = created_by;
        this.gs_bucket = gs_bucket;
        this.full_file_path = full_file_path;
        this.download_url = download_url;
        this.title = title;
        this.description = description;
        this.id = id;
    }

    static KEY_ID = "id";

    CreatedOn() {
        return new Date(this.created_on);
    }

    DownloadURL() {
        return this.download_url;
    }

    Title() {
        return this.title;
    }

    Description() {
        return this.description;
    }

    Id() {
        return this.id;
    }

    FullFilePath() {
        return this.full_file_path;
    }

    static async CreateDoc({ created_by, created_on, gs_bucket, full_file_path, download_url, title, description }) {
        let err_msg = null;
        const db = getFirestore(app);
        let new_notice = null;
        try {
            const notices_collection = collection(db, globals.Collections.NOTICES);
            const notice_ref = doc(notices_collection).withConverter(this.FirestoreConverter);
            new_notice = new Notice(created_on, created_by, gs_bucket, full_file_path, download_url, title, description);
            await setDoc(notice_ref, new_notice);
            new_notice[this.KEY_ID] = notice_ref.id;
        } catch (err) {
            console.log(err);
            err_msg = err;
        }

        if (err_msg) {
            console.log(err_msg);
            return {
                status: false,
                message: err_msg
            };
        }

        return {
            status: true,
            data: new_notice
        };
    }

    static async GetDataFromFirestore(notice_id) {
        if (!notice_id) return;
        const db = getFirestore(app);
        try {
            const notice_ref = doc(db, globals.Collections.NOTICES, notice_id).withConverter(this.FirestoreConverter);
            console.debug("Querying data");
            const notice_doc = await getDoc(notice_ref);
            if (notice_doc.exists()) {
                const d = notice_doc.data();
                return d;
            }
        } catch (err) {
            console.error(err);
        }

        return null;
    }

    static async GetDocsFromFirestore() {
        const db = getFirestore(app);
        let notices = [];
        let err_msg = null;
        try {
            const notices_collection = collection(db, globals.Collections.NOTICES).withConverter(this.FirestoreConverter);
            const notice_docs = await getDocs(notices_collection);
            notice_docs.forEach(notice_doc => {
                const notice_data = notice_doc.data();
                notices.push(notice_data);
            });
        } catch (err) {
            console.error(err); // TODO: Migrate this into a toast message
            err_msg = err;
        }

        return {
            status: err_msg ? false : true,
            message: err_msg,
            data: notices
        };
    }

    static async DeleteNotice(notice_id) {
        const db = getFirestore(app);
        let err_msg = null;
        try {
            const notice_ref = doc(db, globals.Collections.NOTICES, notice_id).withConverter(this.FirestoreConverter);
            const notice_doc = await getDoc(notice_ref);
            if (notice_doc.exists()) {
                const d = notice_doc.data();
                const file_path = d.full_file_path;
                const storage = getStorage();
                const notice_storage_ref = ref(storage, file_path);
                await deleteObject(notice_storage_ref);
                await deleteDoc(notice_ref);
            }
        } catch (err) {
            err_msg = err;
        }

        return {
            status: err_msg ? false : true,
            message: err_msg
        };
    }

    static FirestoreConverter = {
        toFirestore: notice => {
            return {
                created_by: notice.created_by,
                created_on: notice.created_on,
                gs_bucket: notice.gs_bucket,
                full_file_path: notice.full_file_path,
                download_url: notice.download_url,
                title: notice.title,
                description: notice.description
            };
        },

        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Notice(
                data.created_on,
                data.created_by,
                data.gs_bucket,
                data.full_file_path,
                data.download_url,
                data.title,
                data.description,
                snapshot.id
            );
        }
    };
}

export default Notice;
