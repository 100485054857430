import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "flowbite-react";
import { useSearchParams } from "react-router-dom";

import { StdContext } from "../context/StdContext";
import { UserCacheContext } from "../context/UserCacheContext";
import User from "../helpers/User";
import ListBookings from "../components/bookings/ListBookings";

const Bookings = () => {
    const [searchParams] = useSearchParams();
    const uid = searchParams.get("uid");
    const [display_user_name, SetDisplayUserName] = useState(null);
    const std_context = useContext(StdContext);
    const user_info_context = useContext(UserCacheContext);
    useEffect(() => {
        if (std_context.NoData()) return;
        const FetchUserData = async () => {
            let user_info = null;
            if (uid && std_context.IsAdmin()) {
                user_info = await user_info_context.GetUser("+" + uid.trim());
            } else {
                user_info = await user_info_context.GetUser(std_context.user_phone_number);
            }

            if (user_info) SetDisplayUserName(user_info[User.KEY_NAME]);
        };

        FetchUserData();
    }, []);

    if (!display_user_name) {
        return (
            <Card className="mt-5 text-center">
                <div className="flex flex-col items-center p-10 gap-10">
                    <div className="tracking-wide text-2xl">User account could not be found</div>
                </div>
            </Card>
        );
    }

    return (
        <>
            <Helmet>
                <title>Bookings | fdblock.org</title>
            </Helmet>
            <div className="w-full">
                <Card className="p-2 mt-10">
                    <h3 className="text-2xl text-right">{display_user_name}'s bookings</h3>
                    <ListBookings uid={uid} />
                </Card>
            </div>
        </>
    );
};

export default Bookings;
