import React, { createContext, useState } from "react";
import User from "../helpers/User";

export const UserCacheContext = createContext();

export const UserCacheContextProvider = ({ children }) => {
    const [cached_user_info, SetCachedUserInfo] = useState({});

    const GetUser = async user_id => {
        if (user_id in cached_user_info) {
            return cached_user_info[user_id];
        }

        const user_id_data = await User.GetDataFromFirestore(user_id, true /* fetch_all */);
        SetCachedUserInfo(prev_cached_user_info => ({
            ...prev_cached_user_info,
            [user_id]: user_id_data
        }));

        return user_id_data; // The state might take some time to get updated, return the fetched data immediately
    };

    return (
        <UserCacheContext.Provider
            value={{
                GetUser: GetUser
            }}
        >
            {children}
        </UserCacheContext.Provider>
    );
};

export default UserCacheContextProvider;
